.tabs-div {
  padding-bottom: 5px;
  border-bottom: 2px solid #e8eaeb;

  span {
    padding: 5px;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
  }
}