.table-body{
    td{
        padding: 12px 20px!important;
    }
}
.tabs-div{
    padding-bottom: 0;

    .tab{
        padding: 8px 20px;
        display: inline-block;
    }
    .active-tab{
        background-color: var(--primary);
        border-radius: 4px 4px 0 0;
        color: #fff;
    }
}
.addMember .avatar-preview2{
    background-color: hsl(0, 0%, 90%) !important;
    height: 13rem!important;
    width: 13rem!important;
    position: relative;
    border-radius: 30px;
    border: 3px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    & > div {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
    }
    img {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}
  
@media (max-width: 768px) {
    .addMember .avatar-preview2 {
        height: 10rem!important;
        width: 10rem!important;
        img{
            width: 75%!important;
        }
    }
}
@media (max-width: 500px) {
    .addMember .avatar-preview2 {
        height: 10rem!important;
        width: 100%!important;
        img{
            width: 75%!important;
        }
    }
}